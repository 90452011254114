<template>
  <div>
    <div class="relative">
      <Label>
        <input
          v-model="computedModelValue"
          ref="$inputRef"
          :type="type || 'text'"
          :class="[
            isError && 'border-error-red text-error-red',
            disabled && 'border-dark-grey',
            inputClassNames,
          ]"
          class="peer w-full border border-black py-3 pl-2 pr-14 text-m-medium outline-none transition-colors disabled:text-dark-grey md:text-medium"
          placeholder=""
          :disabled="disabled"
          :required="required"
          :aria-label="ariaLabel"
          :maxLength="maxLength"
          :autocomplete="autoComplete"
          :name="name"
          @blur="$emit('blur')"
          @focusin="isFocused = true"
          @focusout="isFocused = false"
          @input="$emit('input', $event)"
        />

        <IconSearch
          v-show="hasSearchIcon"
          :class="computedIsFocusedOrHasValue && '!opacity-0'"
          class="absolute left-4 top-1/2 h-[1.375rem] w-[1.375rem] -translate-y-1/2 transition-opacity opacity-100"
        />

        <IconCheckmark
          v-show="
            !isError &&
            !disabled &&
            computedModelValue?.trim().length >= 3 &&
            !isPromoInput &&
            !hasSearchIcon
          "
          class="absolute right-2 top-1/2 h-[22px] w-[22px] -translate-y-1/2 stroke-validation-green"
        />

        <span v-if="label" :class="computedFloatingSpanClassNames">
          {{ label }}
        </span>
        <span
          v-if="isPromoInput"
          :class="[!computedModelValue && '!cursor-default']"
          class="absolute right-[1rem] top-1/2 z-10 -translate-y-1/2 cursor-pointer text-sm underline"
          @click="$emit('applyPromoCode')"
          >{{ $t("checkout.apply") }}</span
        >
      </Label>
    </div>

    <div v-if="supportText">
      <span
        :class="isError && 'text-error-red'"
        class="ps-2 text-m-sm text-dark-grey md:text-sm"
        >{{ supportText }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, defineExpose } from "vue";
import { IconCheckmark } from "@/components/atoms/Icon";
import Label from "@/components/atoms/Label";
import { IconSearch } from "@/components/atoms/Icon";

const props = defineProps<{
  modelValue: string;
  label?: string;
  supportText?: string;
  isError?: boolean;
  disabled?: boolean;
  required?: boolean;
  type?: string;
  ariaLabel?: string;
  isPromoInput?: boolean;
  hasSearchIcon?: boolean;
  maxLength?: number;
  inputClassNames?: string;
  autoComplete?: string;
  name?: string;
}>();

const emit = defineEmits([
  "update:modelValue",
  "blur",
  "applyPromoCode",
  "input",
]);
const computedModelValue = computed<string>({
  get: () => props.modelValue,
  set: (value: string) => emit("update:modelValue", value),
});

const $inputRef = ref<HTMLInputElement | null>(null);

defineExpose({
  ref: $inputRef,
});

const isFocused = ref(false);
const computedIsFocusedOrHasValue = computed(() => {
  return isFocused.value || computedModelValue.value;
});
const computedFloatingSpanClassNames = computed(() => {
  const baseClassNames =
    "absolute px-1 left-0 top-0 transition-all translate-x-1 translate-y-3 bg-transparent text-m-medium text-dark-grey md:text-medium";

  const translateX = props.hasSearchIcon
    ? "ml-10 -translate-x-8"
    : "translate-x-2";

  if (computedIsFocusedOrHasValue.value) {
    return `${baseClassNames} !-translate-y-2.5 ${translateX} !text-m-sm !text-black md:!text-sm !bg-white`;
  }

  return `${baseClassNames} ${translateX}`;
});
</script>
